<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
export default {
  name: "app",
};
</script>

<style lang="stylus">
#app {
  font: 16px / 1.5 numFont, PingFangSC-Regular, Tahoma, Microsoft Yahei, sans-serif;
}

body {
  margin: 0;
}
</style>
