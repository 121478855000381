<template>
  <div></div>
</template>
<script>
  export default {
    created(){
      const pageName = this.$route.query?.id;
      if(pageName){
        if(pageName == 'index') this.$router.push('/')
        else this.$router.push(pageName)
      }else{
        this.$router.push('404')
      }
      
    }
  }
</script>


