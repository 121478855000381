import Vue from 'vue'
import App from './App.vue'
import router from './router'
import Vant from 'vant';
import 'vant/lib/index.less';

Vue.use(Vant);

import { VueJsonp } from 'vue-jsonp'   //注这里必须用{ }包裹否者会报错。
Vue.use(VueJsonp)

import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
Vue.use(ElementUI);

Vue.config.productionTip = false

new Vue({
  router,
  render: h => h(App),
}).$mount('#app')