import Vue from 'vue'
import Router from 'vue-router'
import { getToken } from '../uitls/cookies'

import Layout from '@/views/layout'
import Index2 from '@/views/index_new'
import Index from '@/views/index2_new'
import Index3 from '@/views/index3_new'
import Record from '@/views/record'
import Record2 from '@/views/record2'
import Miniprogrampage from '@/views/miniprogrampage'
import Success from '@/views/success'

Vue.use(Router)

const router = new Router({
  // mode: 'history',   //history hash
  routes: [
    {
      path: '/',
      name: 'Layout',
      component: Layout,
      children: [
        {
          path: '/',
          name: 'Index',
          component: Index,
          meta: { title: '信息采集' }
        },
        {
          path: '/index2',
          name: 'Index2',
          component: Index2,
          meta: { title: '信息采集' }
        },
        {
          path: '/index3',
          name: 'Index3',
          component: Index3,
          meta: { title: '信息采集' }
        },
        {
          path: '/record',
          name: 'Record',
          component: Record,
          meta: { title: '记录信息' }
        },
        {
          path: '/record2',
          name: 'Record2',
          component: Record2,
          meta: { title: '记录信息' }
        },
        {
          path: '/miniprogrampage',
          name: 'Miniprogrampage',
          component: Miniprogrampage,
          meta: { title: '页面跳转' }
        },
        {
          path: '/success',
          name: 'Success',
          component: Success,
          meta: { title: '操作成功' }
        },
        // {
        //   path: '*',
        //   component: () => import('../components/404.vue'),
        //   meta: { title: '404' }
        // }
      ]
    },
    {
      path: '/login',
      name: 'login',
      meta: { title: '登录' },
      component: () => import('../views/login')
    },
    {
      path: '*',
      component: () => import('../components/404.vue'),
      meta: { title: '404' }
    }

  ]
})

import defaultSettings from '@/settings'

const title = defaultSettings.title || '首页'

router.beforeEach((to, from, next) => {
  // console.log('to', to)

  // console.log('from', from)
  if (to.meta.title) document.title = to.meta.title + ' - ' + title

  // if (to.path === '/login') {
  //   next()
  // } else {
  //   if (!getToken()) {
  //     next('/login')
  //   } else
  next()
  // }
})

// router.beforeResolve((to, from, next) => {
/* 必须调用 `next` */

// console.log('to', to)

// console.log('from', from)

// next()
// })

export default router