<template>
  <div class="container">
    <div class="wrapper" v-if="!showModal">
      <div class="list-title">受试者观察表记录</div>
      <div class="list">
        <van-form @submit="onSubmit" @failed="onFailed">
          <div class="list-item">
            <van-field
              v-model="formData.name"
              name="name"
              label="姓名"
              maxlength="10"
              placeholder="请填写姓名"
              :rules="[{ required: true, message: '请填写姓名' }]"
            />
            
            <van-field
              v-model="formData.phone"
              name="phone"
              label="联系方式"
              type="tel"
              maxlength="13"
              placeholder="请填写联系方式"
              :rules="[{ pattern: phoneVerify, message: '请填写正确的联系方式' }]"
            />
          </div>

          <div class="list-item">
            <div class="list-item-title">第一次穿戴记录</div>
            <van-field
              readonly
              clickable
              name="startDate1"
              :value="formData.startDate1"
              label="测量时间段"
              placeholder="点击选择测量开始时间"
              @click="showStartDatePicker1"
              :rules="[{ required: true, message: '请选择测量开始时间' }]"
            />
            <van-popup v-model="showStartDate1" position="bottom">
              <van-datetime-picker
                v-model="defaultStartDate1"
                v-if="showStartDate1"
                type="datetime"
                @confirm="onStartDateConfirm1"
                @cancel="showStartDate1 = false"
                :min-date="minDate"
                :max-date="maxDate"
                :formatter="formatter"
              />
            </van-popup>
            <van-field
              readonly
              clickable
              name="endDate1"
              label=" "
              :value="formData.endDate1"
              placeholder="点击选择测量结束时间"
              @click="showEndDatePicker1"
              :rules="[{ required: true, message: '请选择测量结束时间' }]"
            />
            <van-popup v-model="showEndDate1" position="bottom">
              <van-datetime-picker
                v-model="defaultEndDate1"
                v-if="showEndDate1"
                type="datetime"
                @confirm="onEndDateConfirm1"
                @cancel="showEndDate1 = false"
                :min-date="minDate"
                :max-date="maxDate"
                :formatter="formatter"
              />
            </van-popup>

            <van-field
              readonly
              clickable
              name="measureStatus1"
              :value="formData.measureStatus1"
              label="测量状态"
              placeholder="点击选择测量状态"
              @click="showStatusPicker1"
              :rules="[{ required: true, message: '请选择测量状态' }]"
            />
            <van-popup v-model="showStatus1" position="bottom">
              <van-picker
                :default-index="defaultStatus1"
                v-if="showStatus1"
                show-toolbar
                :columns="statusColumns"
                @confirm="onStatusConfirm1"
                @cancel="showStatus1 = false"
              />
            </van-popup>

            <div class="checkbox-label">身体感受</div>
            <van-field name="bodyFeeling1" label="" :rules="[{ required: true, message: '请选择身体感受' }]">
              <template #input>
                <van-checkbox-group v-model="formData.bodyFeeling1" direction="horizontal">
                  <van-checkbox name="无症状" shape="square" :disabled="disabledNot1">无症状</van-checkbox>
                  <van-checkbox name="胸闷" shape="square" :disabled="disabledHas1">胸闷</van-checkbox>
                  <van-checkbox name="心悸" shape="square" :disabled="disabledHas1">心悸</van-checkbox>
                  <van-checkbox name="头晕" shape="square" :disabled="disabledHas1">头晕</van-checkbox>
                  <van-checkbox name="气短" shape="square" :disabled="disabledHas1">气短</van-checkbox>
                  <van-checkbox name="其他" shape="square" :disabled="disabledHas1">其他</van-checkbox>
                </van-checkbox-group>
              </template>
            </van-field>
            <van-field
              v-if="formData.bodyFeeling1.indexOf('其他') != -1"
              v-model="formData.bodyFeelingOther1"
              name="bodyFeelingOther1"
              maxlength="8"
              placeholder="请填写其他身体感受"
              :rules="[{ required: formData.bodyFeeling1.indexOf('其他') != -1?true:false, message: '请填写其他身体感受' }]"
            />
          </div>

          <div class="list-item">
            <div class="list-item-title">第二次穿戴记录</div>
            <van-field
              readonly
              clickable
              name="startDate2"
              :value="formData.startDate2"
              label="测量时间段"
              placeholder="点击选择测量开始时间"
              @click="showStartDatePicker2"
              :rules="[{ required: true, message: '请选择测量开始时间' }]"
            />
            <van-popup v-model="showStartDate2" position="bottom">
              <van-datetime-picker
                v-model="defaultStartDate2"
                v-if="showStartDate2"
                type="datetime"
                @confirm="onStartDateConfirm2"
                @cancel="showStartDate2 = false"
                :min-date="minDate"
                :max-date="maxDate"
                :formatter="formatter"
              />
            </van-popup>
            <van-field
              readonly
              clickable
              name="endDate2"
              label=" "
              :value="formData.endDate2"
              placeholder="点击选择测量结束时间"
              @click="showEndDatePicker2"
              :rules="[{ required: true, message: '请选择测量结束时间' }]"
            />
            <van-popup v-model="showEndDate2" position="bottom">
              <van-datetime-picker
                v-model="defaultEndDate2"
                v-if="showEndDate2"
                type="datetime"
                @confirm="onEndDateConfirm2"
                @cancel="showEndDate2 = false"
                :min-date="minDate"
                :max-date="maxDate"
                :formatter="formatter"
              />
            </van-popup>

            <van-field
              readonly
              clickable
              name="measureStatus2"
              :value="formData.measureStatus2"
              label="测量状态"
              placeholder="点击选择测量状态"
              @click="showStatusPicker2"
              :rules="[{ required: true, message: '请选择测量状态' }]"
            />
            <van-popup v-model="showStatus2" position="bottom">
              <van-picker
                :default-index="defaultStatus2"
                v-if="showStatus2"
                show-toolbar
                :columns="statusColumns"
                @confirm="onStatusConfirm2"
                @cancel="showStatus2 = false"
              />
            </van-popup>

            <div class="checkbox-label">身体感受</div>
            <van-field name="bodyFeeling2" label="" :rules="[{ required: true, message: '请选择身体感受' }]">
              <template #input>
                <van-checkbox-group v-model="formData.bodyFeeling2" direction="horizontal">
                  <van-checkbox name="无症状" shape="square" :disabled="disabledNot2">无症状</van-checkbox>
                  <van-checkbox name="胸闷" shape="square" :disabled="disabledHas2">胸闷</van-checkbox>
                  <van-checkbox name="心悸" shape="square" :disabled="disabledHas2">心悸</van-checkbox>
                  <van-checkbox name="头晕" shape="square" :disabled="disabledHas2">头晕</van-checkbox>
                  <van-checkbox name="气短" shape="square" :disabled="disabledHas2">气短</van-checkbox>
                  <van-checkbox name="其他" shape="square" :disabled="disabledHas2">其他</van-checkbox>
                </van-checkbox-group>
              </template>
            </van-field>
            <van-field
              v-if="formData.bodyFeeling2.indexOf('其他') != -1"
              v-model="formData.bodyFeelingOther2"
              name="bodyFeelingOther2"
              maxlength="8"
              placeholder="请填写其他身体感受"
              :rules="[{ required: formData.bodyFeeling2.indexOf('其他') != -1?true:false, message: '请填写其他身体感受' }]"
            />
          </div>

          <div class="list-item">
            <div class="list-item-title">第三次穿戴记录</div>
            <van-field
              readonly
              clickable
              name="startDate3"
              :value="formData.startDate3"
              label="测量时间段"
              placeholder="点击选择测量开始时间"
              @click="showStartDatePicker3"
              :rules="[{ required: true, message: '请选择测量开始时间' }]"
            />
            <van-popup v-model="showStartDate3" position="bottom">
              <van-datetime-picker
                v-model="defaultStartDate3"
                v-if="showStartDate3"
                type="datetime"
                @confirm="onStartDateConfirm3"
                @cancel="showStartDate3 = false"
                :min-date="minDate"
                :max-date="maxDate"
                :formatter="formatter"
              />
            </van-popup>
            <van-field
              readonly
              clickable
              name="endDate3"
              label=" "
              :value="formData.endDate3"
              placeholder="点击选择测量结束时间"
              @click="showEndDatePicker3"
              :rules="[{ required: true, message: '请选择测量结束时间' }]"
            />
            <van-popup v-model="showEndDate3" position="bottom">
              <van-datetime-picker
                v-model="defaultEndDate3"
                v-if="showEndDate3"
                type="datetime"
                @confirm="onEndDateConfirm3"
                @cancel="showEndDate3 = false"
                :min-date="minDate"
                :max-date="maxDate"
                :formatter="formatter"
              />
            </van-popup>

            <van-field
              readonly
              clickable
              name="measureStatus3"
              :value="formData.measureStatus3"
              label="测量状态"
              placeholder="点击选择测量状态"
              @click="showStatusPicker3"
              :rules="[{ required: true, message: '请选择测量状态' }]"
            />
            <van-popup v-model="showStatus3" position="bottom">
              <van-picker
                :default-index="defaultStatus3"
                v-if="showStatus3"
                show-toolbar
                :columns="statusColumns"
                @confirm="onStatusConfirm3"
                @cancel="showStatus3 = false"
              />
            </van-popup>

            <div class="checkbox-label">身体感受</div>
            <van-field name="bodyFeeling3" label="" :rules="[{ required: true, message: '请选择身体感受' }]">
              <template #input>
                <van-checkbox-group v-model="formData.bodyFeeling3" direction="horizontal">
                  <van-checkbox name="无症状" shape="square" :disabled="disabledNot3">无症状</van-checkbox>
                  <van-checkbox name="胸闷" shape="square" :disabled="disabledHas3">胸闷</van-checkbox>
                  <van-checkbox name="心悸" shape="square" :disabled="disabledHas3">心悸</van-checkbox>
                  <van-checkbox name="头晕" shape="square" :disabled="disabledHas3">头晕</van-checkbox>
                  <van-checkbox name="气短" shape="square" :disabled="disabledHas3">气短</van-checkbox>
                  <van-checkbox name="其他" shape="square" :disabled="disabledHas3">其他</van-checkbox>
                </van-checkbox-group>
              </template>
            </van-field>
            <van-field
              v-if="formData.bodyFeeling3.indexOf('其他') != -1"
              v-model="formData.bodyFeelingOther3"
              name="bodyFeelingOther3"
              maxlength="8"
              placeholder="请填写其他身体感受"
              :rules="[{ required: formData.bodyFeeling3.indexOf('其他') != -1?true:false, message: '请填写其他身体感受' }]"
            />
          </div>

          <div class="form-submit">
            <van-button round block type="info" native-type="submit">提交</van-button>
          </div>
        </van-form>
      </div>
    </div>
    <van-dialog
      v-model="showModal"
      title="提示"
      :show-cancel-button=false
      :show-confirm-button=false
    >
      <van-form @submit="modalSubmit">
        <van-field
          v-model="formData.visitCode"
          name="visitCode"
          placeholder="请输入口令"
          :rules="[{ required: true, message: '口令不能为空' }]"
        />
        <div style="margin: 16px;">
          <van-button round block type="info" native-type="submit">验证口令</van-button>
        </div>
      </van-form>
    </van-dialog>
  </div>
</template>

<script>
  import '@/assets/stylus/record/index.styl'
  import { 
    Notify,
    Toast,
    Dialog,
  } from 'vant';
  import { apiVerify, apiRecord1 } from '@/request'
  import { DATE_FORMAT } from '@/actions'

  export default {
    name: 'Index',
    data() {
      return {
        showModal: false,
        showStartDate1: false,
        showEndDate1: false,
        showStartDate2: false,
        showEndDate2: false,
        showStartDate3: false,
        showEndDate3: false,
        showStatus1: false,
        showStatus2: false,
        showStatus3: false,
        formData: {
          phone: process.env.NODE_ENV == 'development' ? '13632453247' : '',
          name:  process.env.NODE_ENV == 'development' ? '哈哈' : '',
          visitCode: process.env.NODE_ENV == 'development' ? '6673' : '',
          startDate1: '',
          endDate1: '',
          measureStatus1:'',
          bodyFeeling1:[],
          bodyFeelingOther1: '',
          startDate2: '',
          endDate2: '',
          measureStatus2:'',
          bodyFeeling2:[],
          bodyFeelingOther2: '',
          startDate3: '',
          endDate3: '',
          measureStatus3:'',
          bodyFeeling3:[],
          bodyFeelingOther3: '',
        },
        minDate: new Date(2021, 11, 1 , 0 ,0),
        maxDate: new Date(2022, 1, 28 , 23 ,59),
        defaultStartDate1: new Date(),
        defaultEndDate1: new Date(),
        defaultStartDate2: new Date(),
        defaultEndDate2: new Date(),
        defaultStartDate3: new Date(),
        defaultEndDate3: new Date(),
        defaultBodyFeeling1: '0',
        defaultBodyFeeling2: '0',
        defaultBodyFeeling3: '0',
        defaultStatus1: '0',
        defaultStatus2: '0',
        defaultStatus3: '0',
        disabledNot1: false,
        disabledHas1: false,
        disabledNot2: false,
        disabledHas2: false,
        disabledNot3: false,
        disabledHas3: false,
        phoneVerify: /^((0\d{2,3}-\d{8})|(1[3-9]{1}\d{9}))$/,
        statusColumns: ['工作','休息','值班/加班'],
      }
    },
    mounted() {
      this.initModal()
    },
    beforeDestroy() {
    },
    watch: {
      "formData.bodyFeeling1"(v){
        if(v.length > 0 && v[0]=='无症状') this.disabledHas1 = true
        else if(v.length > 0) this.disabledNot1 = true
        else {
          this.disabledNot1 = false
          this.disabledHas1 = false
        }
      },
      "formData.bodyFeeling2"(v){
        if(v.length > 0 && v[0]=='无症状') this.disabledHas2 = true
        else if(v.length > 0) this.disabledNot2 = true
        else {
          this.disabledNot2 = false
          this.disabledHas2 = false
        }
      },
      "formData.bodyFeeling3"(v){
        if(v.length > 0 && v[0]=='无症状') this.disabledHas3 = true
        else if(v.length > 0) this.disabledNot3 = true
        else {
          this.disabledNot3 = false
          this.disabledHas3 = false
        }
      },
    },
    methods: {
      initModal(){
        this.showModal = true
      },
      async modalSubmit(values){
        console.log('submit1', values);
        if(values.visitCode){
          const {resultCode} = await apiVerify({...values})
          if(resultCode == '200'){
            this.formData.visitCode = values.visitCode
            this.showModal = false
            Toast.success('验证成功');
          }else{
            Toast.fail('验证信息失败');
          }
        }
      },
      async onSubmit(values){
        let obj = this.formData
        delete obj.bodyFeeling1
        delete obj.bodyFeeling2
        delete obj.bodyFeeling3
        delete obj.bodyFeelingOther1
        delete obj.bodyFeelingOther2
        delete obj.bodyFeelingOther3
        obj.bodyFeeling1 = values.bodyFeeling1.toString()+(values.bodyFeelingOther1?','+values.bodyFeelingOther1:'')
        obj.bodyFeeling2 = values.bodyFeeling2.toString()+(values.bodyFeelingOther2?','+values.bodyFeelingOther2:'')
        obj.bodyFeeling3 = values.bodyFeeling3.toString()+(values.bodyFeelingOther3?','+values.bodyFeelingOther3:'')

        console.log('submit', obj);
        Dialog.confirm({
          title: '信息确认',
          message: '请确认以上填写的信息真实无误？'
        }).then(async ()=>{
          const { resultCode } = await apiRecord1({...obj})
          if(resultCode == '200'){
            Object.assign(this.$data.formData, this.$options.data().formData)
            this.$router.push('success')
          }else if(resultCode == '506'){
            Toast.fail('该账号已提交，请勿重复提交');
          }else{
            Toast.fail('提交失败');
          }
        }).catch(()=>{
          // console.log('取消')
        })
      },
      onFailed(err){
        if(err.errors.length > 0) {
          Notify({ type: 'danger', message: err.errors[0].message });
        }
      },
      showStartDatePicker1(){
        this.showStartDate1 = true
        if(this.formData.startDate1) this.defaultStartDate1 = new Date(this.formData.startDate1.replace(/-/g, '/'))
        else this.defaultStartDate1 = new Date()
      },
      showEndDatePicker1(){
        this.showEndDate1 = true
        if(this.formData.endDate1) this.defaultEndDate1 = new Date(this.formData.endDate1.replace(/-/g, '/'))
        else this.defaultEndDate1 = new Date()
      },
      onStartDateConfirm1(time){
        this.formData.startDate1 = DATE_FORMAT.originToDateTime(time)
        this.showStartDate1 = false;
      },
      onEndDateConfirm1(time){
        this.formData.endDate1 = DATE_FORMAT.originToDateTime(time)
        this.showEndDate1 = false;
      },
      showStartDatePicker2(){
        this.showStartDate2 = true
        if(this.formData.startDate2) this.defaultStartDate2 = new Date(this.formData.startDate2.replace(/-/g, '/'))
        else this.defaultStartDate2 = new Date()
      },
      showEndDatePicker2(){
        this.showEndDate2 = true
        if(this.formData.endDate2) this.defaultEndDate2 = new Date(this.formData.endDate2.replace(/-/g, '/'))
        else this.defaultEndDate2 = new Date()
      },
      onStartDateConfirm2(time){
        this.formData.startDate2 = DATE_FORMAT.originToDateTime(time)
        this.showStartDate2 = false;
      },
      onEndDateConfirm2(time){
        this.formData.endDate2 = DATE_FORMAT.originToDateTime(time)
        this.showEndDate2 = false;
      },
      showStartDatePicker3(){
        this.showStartDate3 = true
        if(this.formData.startDate3) this.defaultStartDate3 = new Date(this.formData.startDate3.replace(/-/g, '/'))
        else this.defaultStartDate3 = new Date()
      },
      showEndDatePicker3(){
        this.showEndDate3 = true
        if(this.formData.endDate3) this.defaultEndDate3 = new Date(this.formData.endDate3.replace(/-/g, '/'))
        else this.defaultEndDate3 = new Date()
      },
      onStartDateConfirm3(time){
        this.formData.startDate3 = DATE_FORMAT.originToDateTime(time)
        this.showStartDate3 = false;
      },
      onEndDateConfirm3(time){
        this.formData.endDate3 = DATE_FORMAT.originToDateTime(time)
        this.showEndDate3 = false;
      },
      showStatusPicker1(){
        this.showStatus1 = true
        if(this.formData.measureStatus1) {
          const key = this.statusColumns.map((item,index)=>{
            if(item == this.formData.measureStatus1) return index
          })
          this.defaultStatus1 = key.join('')
        }
      },
      showStatusPicker2(){
        this.showStatus2 = true
        if(this.formData.measureStatus2) {
          const key = this.statusColumns.map((item,index)=>{
            if(item == this.formData.measureStatus2) return index
          })
          this.defaultStatus2 = key.join('')
        }
      },
      showStatusPicker3(){
        this.showStatus3 = true
        if(this.formData.measureStatus3) {
          const key = this.statusColumns.map((item,index)=>{
            if(item == this.formData.measureStatus3) return index
          })
          this.defaultStatus3 = key.join('')
        }
      },
      onStatusConfirm1(val){
        this.formData.measureStatus1 = val
        this.showStatus1 = false;
      },
      onStatusConfirm2(val){
        this.formData.measureStatus2 = val
        this.showStatus2 = false;
      },
      onStatusConfirm3(val){
        this.formData.measureStatus3 = val
        this.showStatus3 = false;
      },
      formatter(type, val) {
        if (type === 'year') {
          return `${val}年`;
        } else if (type === 'month') {
          return `${val}月`;
        }else if (type === 'day') {
          return `${val}日`;
        }
        return val;
      },
    },
  }
</script>

<style lang="stylus" rel="stylesheet/stylus">
</style>

