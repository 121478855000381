import axios from 'axios'

// 创建axios实例
let service = axios.create({
  //baseURL: process.env.BASE_API, // api的base_url
  //baseURL: process.env.VUE_APP_URL, // api的base_url
  timeout: 200000,   // 请求超时时间
  //retry: 3,   // 设置请求失败之后重新请求次数
  //retryDelay: 5000   // 设置请求失败之后再次发起请求的间隔
})

/**
 *  request拦截器(请求)
 * */
// service.interceptors.request.use(config => {
//   return config
// }, error => {
//   return Promise.reject(error)
// })


/**
 *  respone拦截器(响应)
 * */
service.interceptors.response.use(response => {
  return response.data
}, error => {
  return Promise.reject(error)
})

// export default service


/**
 * get 请求方法
 * @param url
 * @param params
 * @returns {Promise}
 */
export function get(url, params) {
  return new Promise((resolve, reject) => {
    service.get(url, {
      params
    }).then(response => {
      resolve(response)
    })
      .catch(err => {
        console.log(err)
        reject('请求已超时！')
      })
  })
}
// export function get(url, params, options) {
//   return service(url, {
//       method:'GET',
//       params: { ...params },
//       ...(options || {}),
//     })
//   }
export function post(url, params, config) {
  return new Promise((resolve, reject) => {
    service.post(url, params, config)
      .then(response => {
        resolve(response)
      }).catch(err => {
        console.log(err)
        reject('请求已超时！')
      })
  })
}

/**
 * get 下载文件请求方法
 * @param url
 * @param params
 * @returns {Promise}
 */
export function download(url, params) {
  return new Promise((resolve, reject) => {
    axios.get(url, {
      params,
      responseType: 'blob'
    }).then(response => {
      resolve(response)
    })
      .catch(err => {
        console.log(err)
        reject('请求已超时！')
      })
  })
}


