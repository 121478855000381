
import { get, post } from './request'

const BASE_URL = "https://test3.mymagicangel.com/"

const BASE_URL_DM = 'https://test3.mymagicangel.com/bmecg/dm/'   // 正式--彭子明

// 口令验证
export const apiVerify = params => get(BASE_URL_DM + 'verify', params)

// 口令和手机号验证
export const apiVerifyPhone = params => get(BASE_URL_DM + 'verifyPhone', params)

// 提交表单
// export const apiSubmit = params => post(BASE_URL_DM + 'collect', params)  // 已弃用
export const apiSubmit = (params, config) => post(BASE_URL + 'usercenter/users/update', params, config)

// 记录信息
export const apiRecord = params => post(BASE_URL_DM + 'record', params)

// 记录信息1
export const apiRecord1 = params => post(BASE_URL_DM + 'record1', params)

// 登录
export const apiLogin = params => post(BASE_URL + 'p/login', params)

// 发送验证码
export const apiSmsCode = params => post(BASE_URL + "p/verify/smsCode", params)

// 获取用户信息
export const apiUserInfo = params => get(BASE_URL + 'usercenter/users/getUserInfoByUserId', params)
