<template>
  <div class="success-page">
    <div>
      <div class="success-img"><img :src="Img"></div>
      <div class="success-title">提交成功</div>
      <div class="success-desc">{{time}}</div>
    </div>
  </div>
</template>

<script>
  import '@/assets/stylus/success/index.styl'
  import Img from '@/assets/images/success.png'
  import { DATE_FORMAT } from '@/actions'
  export default {
    data(){
      return {
        Img,
        time: DATE_FORMAT.originToDateTime(new Date())
      }
    },
    methods: {
    }
  }
</script>

