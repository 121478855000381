<template>
  <div class="container">
    <div class="wrapper">
      <div class="list-title">受试者观察表记录</div>
      <div class="list">
        <template v-if="list.length>0">
          <div class="list-item" v-for="(item,index) in list" :key="index">
            <div class="list-item-title">第{{index == 0?'一':index == 1?'二':index == 2?'三':''}}次提交</div>
            <div class="list-item-label"><label>测量日期：</label>{{item.startDate}}</div>
            <div class="list-item-label"><label></label>{{item.endDate}}</div>
            <div class="list-item-label"><label>测量状态：</label>{{item.measureStatus}}</div>
            <div class="list-item-label"><label>身体感受：</label>{{item.bodyFeeling}}</div>
          </div>
        </template>
        <van-button 
          v-if="formData.collectCount < 3 && formData.collectCount > 0" 
          @click="addModal = true"
          icon="plus" 
          type="info" 
          class="add-btn">
          {{addText}}
        </van-button>
      </div>
    </div>
    <van-action-sheet 
      v-model="addModal" 
      title="受试者观察表" 
      :close-on-click-overlay="false" 
      :closeable="list.length>0?true:false"
    >
      <div class="add-content">
        <van-form @submit="onSubmit" @failed="onFailed">
          <van-field
            readonly
            clickable
            name="startDate"
            :value="formData.startDate"
            label="测量时间段"
            placeholder="点击选择测量开始时间"
            @click="showStartDatePicker"
            :rules="[{ required: true, message: '请选择测量开始时间' }]"
          />
          <van-popup v-model="showStartDate" position="bottom">
            <van-datetime-picker
              v-model="defaultStartDate"
              v-if="showStartDate"
              type="datetime"
              @confirm="onStartDateConfirm"
              @cancel="showPicker = false"
              :min-date="minDate"
              :max-date="maxDate"
              :formatter="formatter"
            />
          </van-popup>
          <van-field
            readonly
            clickable
            name="endDate"
            label=" "
            :value="formData.endDate"
            placeholder="点击选择测量结束时间"
            @click="showEndDatePicker"
            :rules="[{ required: true, message: '请选择测量结束时间' }]"
          />
          <van-popup v-model="showEndDate" position="bottom">
            <van-datetime-picker
              v-model="defaultEndDate"
              v-if="showEndDate"
              type="datetime"
              @confirm="onEndDateConfirm"
              @cancel="showPicker = false"
              :min-date="minDate"
              :max-date="maxDate"
              :formatter="formatter"
            />
          </van-popup>
          <van-field
            readonly
            clickable
            name="measureStatus"
            :value="formData.measureStatus"
            label="测量状态"
            placeholder="点击选择测量状态"
            @click="showStatusPicker"
            :rules="[{ required: true, message: '请选择测量状态' }]"
          />
          <van-popup v-model="showPicker1" position="bottom">
            <van-picker
              :default-index="defaultStatus"
              v-if="showPicker1"
              show-toolbar
              :columns="statusColumns"
              @confirm="onStatusConfirm"
              @cancel="showPicker1 = false"
            />
          </van-popup>
          <!-- <van-field
            readonly
            clickable
            name="bodyFeeling"
            :value="formData.bodyFeeling"
            label="身体感受"
            placeholder="点击选择身体感受"
            @click="showBodyFeelingPicker"
            :rules="[{ required: true, message: '请选择身体感受' }]"
          />
          <van-popup v-model="showPicker2" position="bottom">
            <van-picker
              :default-index="defaultBodyFeeling"
              v-if="showPicker2"
              show-toolbar
              :columns="bodyFeelingColumns"
              @confirm="onBodyFeelingConfirm"
              @cancel="showPicker2 = false"
            />
          </van-popup> -->


          <!-- <div class="form-title">身体感受</div> -->
          <van-field name="bodyFeeling" label="身体感受" :rules="[{ required: true, message: '请选择身体感受' }]">
            <template #input>
              <van-checkbox-group v-model="formData.bodyFeeling">
                <van-checkbox name="无症状" shape="square" :disabled="disabled1">无症状</van-checkbox>
                <van-checkbox name="胸闷" shape="square" :disabled="disabled2">胸闷</van-checkbox>
                <van-checkbox name="心悸" shape="square" :disabled="disabled2">心悸</van-checkbox>
              </van-checkbox-group>
            </template>
          </van-field>

          <div class="sheet-form-submit">
            <van-button round block type="info" native-type="submit">提交</van-button>
          </div>
        </van-form>
      </div>
    </van-action-sheet>
    <van-dialog
      v-model="showModal"
      title="提示"
      :show-cancel-button=false
      :show-confirm-button=false
    >
      <van-form @submit="modalSubmit">
        <van-field
          v-model="visitCode"
          name="visitCode"
          placeholder="请输入口令"
          :rules="[{ required: true, message: '口令不能为空' }]"
        />
        <van-field
          v-model="phone"
          name="phone"
          placeholder="请输入手机号"
          type="tel"
          maxlength="13"
          :rules="[{ pattern: phoneVerify, message: '请填写正确的联系方式' }]"
        />
        <div style="margin: 16px;">
          <van-button round block type="info" native-type="submit">提交验证</van-button>
        </div>
      </van-form>
    </van-dialog>
  </div>
</template>

<script>
  import '@/assets/stylus/record/index.styl'
  import { 
    Notify,
    Toast,
    Dialog,
  } from 'vant';
  import { apiVerifyPhone, apiRecord } from '@/request'
  import { DATE_FORMAT } from '@/actions'

  export default {
    name: 'Record2',
    data() {
      return {
        visitCode: process.env.NODE_ENV == 'development' ? '6673' : '',
        phone: process.env.NODE_ENV == 'development' ? '13632453247' : '',
        showModal: false,
        addModal: false,
        addText: '点击添加一条记录',
        step: 0,
        showStartDate: false,
        showEndDate: false,
        showPicker1: false,
        showPicker2: false,
        formData: {
          startDate: '',
          endDate: '',
          measureStatus:'',
          bodyFeeling:[],
          collectCount: 0,
        },
        minDate: new Date(2020, 0, 1),
        maxDate: new Date(),
        defaultStartDate: new Date(),
        defaultEndDate: new Date(),
        defaultStatus: '0',
        defaultBodyFeeling: '0',
        statusColumns: ['工作','休息','值班/加班'],
        bodyFeelingColumns: ['无症状','胸闷','心悸'],
        disabled1: false,
        disabled2: false,
        phoneVerify: /^((0\d{2,3}-\d{8})|(1[3-9]\d{9}))$/,
        list: [],  // 列表
      }
    },
    mounted() {
      this.initModal()
    },
    beforeDestroy() {
    },
    watch: {
      "formData.bodyFeeling"(v){
        if(v.length > 0 && v[0]=='无症状') this.disabled2 = true
        else if(v.length > 0) this.disabled1 = true
        else {
          this.disabled1 = false
          this.disabled2 = false
        }
      }
    },
    methods: {
      initModal(){
        this.showModal = true
      },
      async modalSubmit(values){
        console.log('submit1', values);
        if(values.visitCode && values.phone){
          const {resultCode, resultData} = await apiVerifyPhone({...values})
          if(resultCode == '200'){
            this.visitCode = values.visitCode
            this.phone = values.phone

            if(resultData.length>0){
              this.list = resultData.map(item=>{
                return {
                  bodyFeeling: item.bodyFeeling,
                  startDate: item.startDate,
                  endDate: item.endDate,
                  measureStatus: item.measureStatus
                }
              })
              this.formData.collectCount = resultData[resultData.length-1].collectCount
            }

            this.showModal = false
            if(resultData.length < 3) this.addModal = true
            Toast.success('验证成功');
          }else{
            Toast.fail('验证信息失败');
          }
        }
      },
      async onSubmit(values){
        const collectCount = this.formData.collectCount
        
        const obj = new Object
        obj.collectCount = collectCount + 1
        obj.visitCode = this.visitCode
        obj.phone = this.phone
        obj.startDate = values.startDate
        obj.endDate = values.endDate
        obj.measureStatus = values.measureStatus
        obj.bodyFeeling = values.bodyFeeling.join(',')
        console.log('submit', obj);
        Dialog.confirm({
          title: '信息确认',
          message: '请确认以上填写的信息真实无误？'
        }).then(async ()=>{
          const { resultCode } = await apiRecord({...obj})
          if(resultCode == '200'){
            Object.assign(this.$data.formData, this.$options.data().formData)
            if(this.formData.collectCount < 2) this.formData.collectCount = collectCount + 1
            this.defaultStatus= '0'
            this.list.push({
              startDate: values.startDate,
              endDate: values.endDate,
              measureStatus: values.measureStatus,
              bodyFeeling: values.bodyFeeling.join(',')
            })
            this.addModal = false
            Toast.success('提交成功');
          }else{
            Toast.fail('提交失败');
          }
        }).catch(()=>{
          // console.log('取消')
        })
      },
      onFailed(err){
        if(err.errors.length > 0) {
          Notify({ type: 'danger', message: err.errors[0].message });
        }
      },
      showStartDatePicker(){
        this.showStartDate = true
        if(this.formData.startDate) this.defaultStartDate = new Date(this.formData.startDate.replace(/-/g, '/'))
        else this.defaultStartDate = new Date()
      },
      showEndDatePicker(){
        this.showEndDate = true
        if(this.formData.endDate) this.defaultEndDate = new Date(this.formData.endDate.replace(/-/g, '/'))
        else this.defaultEndDate = new Date()
      },
      showStatusPicker(){
        this.showPicker1 = true
        if(this.formData.measureStatus) {
          const key = this.statusColumns.map((item,index)=>{
            if(item == this.formData.measureStatus) return index
          })
          this.defaultStatus = key.join('')
        }
      },
      onStartDateConfirm(time){
        this.formData.startDate = DATE_FORMAT.originToDateTime(time)
        this.showStartDate = false;
      },
      onEndDateConfirm(time){
        this.formData.endDate = DATE_FORMAT.originToDateTime(time)
        this.showEndDate = false;
      },
      onStatusConfirm(val){
        this.formData.measureStatus = val
        this.showPicker1 = false;
      },
      formatter(type, val) {
        if (type === 'year') {
          return `${val}年`;
        } else if (type === 'month') {
          return `${val}月`;
        }else if (type === 'day') {
          return `${val}日`;
        }
        return val;
      },
    },
  }
</script>

<style lang="stylus" rel="stylesheet/stylus">
</style>

