<template>
  <div class="container">
    <div class="wrapper" v-if="!showModal">
      <van-sticky>
        <div class="step-box">
          <van-steps
            :active="step"
            active-color="#38f"
            :style="{ width: '60vw', margin: '0 auto' }"
          >
            <van-step>基础信息</van-step>
            <van-step>完成</van-step>
          </van-steps>
        </div>
      </van-sticky>
      <van-form @submit="onSubmit" @failed="onFailed">
        <div v-if="step == 0">
          <div class="form-title">基础信息</div>
          <div class="form-content">
            <van-field
              v-model="formData.name"
              name="name"
              label="姓名"
              maxlength="15"
              placeholder="请填写姓名"
              :rules="[{ required: true, message: '请填写姓名' }]"
            />
            <van-field name="sex" label="性别">
              <template #input>
                <van-radio-group v-model="formData.sex" direction="horizontal">
                  <van-radio name="男">男</van-radio>
                  <van-radio name="女">女</van-radio>
                </van-radio-group>
              </template>
            </van-field>
            <!-- <van-field
              v-model="formData.age"
              name="age"
              label="年龄"
              type="digit"
              maxlength="3"
              placeholder="请填写年龄"
              :rules="[{ required: true, message: '请填写年龄' }]"
            /> -->
            <van-field
              readonly
              clickable
              name="birthday"
              :value="formData.birthday"
              label="出生日期"
              placeholder="点击选择出生年月日"
              @click="showPicker = true"
              :rules="[{ required: true, message: '请选择出生日期' }]"
            />
            <van-popup v-model="showPicker" position="bottom">
              <van-datetime-picker
                v-model="defaultDate"
                type="date"
                @confirm="onBirthdayConfirm"
                @cancel="showPicker = false"
                :min-date="minDate"
                :max-date="maxDate"
              />
            </van-popup>
            <van-field
              v-model="formData.height"
              name="height"
              label="身高"
              type="number"
              maxlength="5"
              placeholder="请填写身高"
              :rules="[{ required: true, message: '请填写身高' }]"
            >
              <span slot="right-icon">cm</span>
            </van-field>
            <van-field
              v-model="formData.weight"
              name="weight"
              label="体重"
              type="number"
              maxlength="5"
              placeholder="请填写体重"
              :rules="[{ required: true, message: '请填写体重' }]"
            >
              <span slot="right-icon">kg</span>
            </van-field>
            <van-field name="smoke" label="吸烟史">
              <template #input>
                <van-radio-group
                  v-model="formData.smoke"
                  direction="horizontal"
                >
                  <van-radio name="否">否</van-radio>
                  <van-radio name="是">是</van-radio>
                </van-radio-group>
              </template>
            </van-field>
            <van-field
              v-if="formData.smoke === '是'"
              v-model="formData.smokeYear"
              name="smokeYear"
              label="吸烟年限"
              type="number"
              placeholder="请填写吸烟年限"
              :rules="[
                {
                  required: formData.smoke === '是' ? true : false,
                  message: '请填写吸烟年限',
                },
              ]"
            >
              <span slot="right-icon">年</span>
            </van-field>
            <van-field name="drink" label="饮酒史">
              <template #input>
                <van-radio-group
                  v-model="formData.drink"
                  direction="horizontal"
                >
                  <van-radio name="否">否</van-radio>
                  <van-radio name="是">是</van-radio>
                </van-radio-group>
              </template>
            </van-field>
            <van-field name="ppm" label="起搏器">
              <template #input>
                <van-radio-group v-model="formData.ppm" direction="horizontal">
                  <van-radio name="无">无</van-radio>
                  <van-radio name="有">有</van-radio>
                </van-radio-group>
              </template>
            </van-field>
            <van-field name="pci" label="心脏支架">
              <template #input>
                <van-radio-group v-model="formData.pci" direction="horizontal">
                  <van-radio name="无">无</van-radio>
                  <van-radio name="有">有</van-radio>
                </van-radio-group>
              </template>
            </van-field>
          </div>

          <!-- <div class="form-title">既往病史</div>
          <div class="form-content">
            <van-field name="medicalHistory">
              <template #input>
                <van-checkbox-group v-model="formData.medicalHistory" direction="horizontal">
                  <van-checkbox name="高血压" shape="square">高血压</van-checkbox>
                  <van-checkbox name="糖尿病" shape="square">糖尿病</van-checkbox>
                  <van-checkbox name="冠心病" shape="square">冠心病</van-checkbox>
                  <van-checkbox name="心律不齐" shape="square">心律不齐</van-checkbox>
                  <van-checkbox name="脑卒中" shape="square">脑卒中</van-checkbox>
                  <van-checkbox name="其他" shape="square">其他</van-checkbox>
                </van-checkbox-group>
              </template>
            </van-field>
            <van-field
              v-if="formData.medicalHistory.indexOf('其他') != -1"
              v-model="formData.medicalHistoryOther"
              name="medicalHistoryOther"
              maxlength="8"
              placeholder="请填写其他既往病史"
              :rules="[{ required: formData.medicalHistory.indexOf('其他') != -1?true:false, message: '请填写其他既往病史' }]"
            />
          </div> -->

          <div class="form-title">平日不适症状</div>
          <div class="form-content">
            <van-field name="symptom">
              <template #input>
                <van-checkbox-group
                  v-model="formData.symptom"
                  direction="horizontal"
                >
                  <van-checkbox
                    v-for="(item, index) in symptomList"
                    :key="index"
                    :name="item"
                    shape="square"
                  >
                    {{ item }}
                  </van-checkbox>
                </van-checkbox-group>
              </template>
            </van-field>
            <van-field
              v-if="formData.symptom.indexOf('其他') != -1"
              v-model="formData.symptomOther"
              name="symptomOther"
              maxlength="8"
              placeholder="请填写其他平日不适症状"
              :rules="[
                {
                  required:
                    formData.symptom.indexOf('其他') != -1 ? true : false,
                  message: '请填写其他平日不适症状',
                },
              ]"
            />
          </div>
        </div>
        <div v-if="step == 1">
          <div class="success-box">
            <div>
              <div class="success-img"><img :src="successImg" /></div>
              <div class="success-title">提交成功</div>
              <div class="success-desc">{{ submitTime }}</div>
            </div>
          </div>
        </div>
        <div class="form-submit-box" v-if="step < 3">
          <van-button
            v-if="step == 0"
            round
            block
            type="info"
            native-type="submit"
            >提交</van-button
          >
        </div>
      </van-form>
    </div>
    <van-dialog
      v-model="showModal"
      title="用户登录"
      :show-cancel-button="false"
      :show-confirm-button="false"
    >
      <van-form @submit="modalSubmit">
        <van-field
          v-model="phone"
          name="phone"
          label="手机号"
          type="tel"
          maxlength="13"
          placeholder="请输入手机号"
          :rules="[
            { required: true, message: '请输入手机号', trigger: 'onSubmit' },
            {
              pattern: phoneVerify,
              message: '请输入正确的手机号',
              trigger: 'onSubmit',
            },
          ]"
          style="margin: 20px 0 0"
        />
        <van-field
          v-model="code"
          name="code"
          label="验证码"
          maxlength="6"
          placeholder="请输入验证码"
          :rules="[
            { required: true, message: '请输入验证码', trigger: 'onSubmit' },
          ]"
        >
          <template #button>
            <van-button
              native-type="button"
              @click="getPhoneCode"
              size="small"
              type="info"
              :disabled="timerValue ? true : false"
              style="width: 78px"
            >
              {{ timerValue ? timerValue : "获取验证码" }}
            </van-button>
          </template>
        </van-field>
        <div style="margin: 16px">
          <van-button
            round
            block
            type="info"
            native-type="submit"
            :loading="loading"
            :disabled="disabled"
            loading-text="加载中..."
            >登录</van-button
          >
        </div>
      </van-form>
    </van-dialog>
  </div>
</template>

<script>
import "@/assets/stylus/index/index.styl";
import successImg from "@/assets/images/success.png";
import { Notify, Toast, Dialog } from "vant";
import { apiSubmit, apiSmsCode, apiLogin, apiUserInfo } from "@/request";
import { DATE_FORMAT } from "@/actions";
import {
  setToken,
  setSessionId,
  setUserInfo,
  getToken,
  getSessionId,
  getUserInfo,
  removeToken,
  removeSessionId,
  removeUserInfo,
} from "@/uitls/cookies";
import "@/uitls/TCaptcha";

export default {
  name: "Index3",
  data() {
    return {
      appid: "2049979663",
      appKey: "w_h5ecg",
      initTimerValue: 60,
      timerValue: "",
      showModal: true,
      step: 0,
      showPicker: false,
      loading: false,
      disabled: process.env.NODE_ENV == "development" ? false : true,
      phone: process.env.NODE_ENV == "development" ? "13800138000" : "",
      code: process.env.NODE_ENV == "development" ? "123456" : "",
      formData: {
        name: "",
        sex: "男",
        birthday: "",
        height: "",
        weight: "",
        smoke: "否",
        smokeYear: "",
        drink: "否",
        ppm: "无",
        pci: "无",
        symptom: [],
        symptomOther: "",
      },
      minDate: new Date(1900, 0, 1),
      maxDate: new Date(),
      defaultDate: new Date(1990, 0, 1),
      phoneVerify: /^(1[3-9]\d{9})$/, // /^((0\d{2,3}-\d{8})|(1[3-9]\d{9}))$/,
      depressed: [], // 抑郁
      anxiety: [], // 焦虑
      stepTop: [],
      submitTime: "",
      successImg,
      symptomList: ["胸闷", "头晕", "心悸", "气短", "晕厥", "黑蒙", "其他"],
    };
  },
  mounted() {
    document.body.scrollTop = 0;
    if (getToken() && getUserInfo()) {
      this.showModal = false;
      let originData = JSON.parse(getUserInfo());
      this.initDatas(originData); // 初始化表达数据
    }
  },
  beforeDestroy() {},
  methods: {
    // 初始化表单数据
    initDatas(obj) {
      if (obj.symptom) {
        let arr1 = this.symptomList;
        let arr2 = obj.symptom.split(",");
        let intersection = arr1.filter((item) => arr2.includes(item)); // 返回交集数组
        let difference = arr2.filter((v) => !arr1.includes(v)); // 返回相差元素数组
        if (difference.length > 0) {
          intersection.push("其他");
        }
        obj.symptom = intersection;
        obj.symptomOther = difference.toString();
      }

      if (obj.birthday)
        this.defaultDate = new Date(
          Number(obj.birthday.split("-")[0]),
          Number(obj.birthday.split("-")[1]) - 1,
          Number(obj.birthday.split("-")[2])
        );

      for (let i in this.formData) {
        if (obj[i]) this.formData[i] = obj[i];
      }
    },

    // 登录提交
    async modalSubmit(values) {
      Toast.loading({ message: "加载中...", forbidClick: true, duration: 0 });
      this.loading = true;
      this.disabled = true;
      let params = JSON.parse(JSON.stringify(values));
      params.appKey = this.appKey;
      const res = await apiLogin({
        ...params,
      });
      if (res.resultCode === "200") {
        setToken(res.resultData.access_token);
        setSessionId(res.resultData.refresh_token);
        const ret = await apiUserInfo({ userId: res.resultData.info.userId });
        if (ret.resultCode === "200") {
          setUserInfo(JSON.stringify(ret.resultData));
          this.initDatas(ret.resultData); // 初始化表单数据
          Toast.success("登录成功");
          this.showModal = false;
        } else Toast.fail(ret.resultMsg);
      } else Toast.fail(res.resultMsg);
      this.loading = false;
      this.disabled = false;
    },
    async onSubmit(values) {
      // 记录上下步骤滚动高度 并 回到到记录位置
      this.getTop();

      const obj = new Object();
      obj.name = this.formData.name;
      obj.sex = this.formData.sex;
      obj.birthday = this.formData.birthday;
      obj.height = this.formData.height;
      obj.weight = this.formData.weight;
      obj.smoke = this.formData.smoke;
      if (this.formData.smoke === "是") obj.smokeYear = this.formData.smokeYear;
      obj.drink = this.formData.drink;
      obj.ppm = this.formData.ppm;
      obj.pci = this.formData.pci;

      let symptom = JSON.parse(JSON.stringify(this.formData.symptom));
      if (symptom.indexOf("其他") != -1) {
        symptom.splice(symptom.indexOf("其他"), 1);
        symptom.push(values.symptomOther);
      }
      obj.symptom = symptom.toString(); // 平日不适症状

      console.log("submit", obj);
      Dialog.confirm({
        title: "信息确认",
        message: "请确认以上填写的信息真实无误？",
      })
        .then(async () => {
          const { resultCode } = await apiSubmit(
            { ...obj },
            { headers: { Authorization: "Bearer " + getToken() } }
          );
          if (resultCode == "200") {
            this.submitTime = DATE_FORMAT.originToDateTime(new Date());
            Object.assign(this.$data.formData, this.$options.data().formData);
            removeToken();
            removeSessionId();
            removeUserInfo();
            this.step = 1;
          } else if (resultCode == "506") {
            Toast.fail("该账号已提交，请勿重复提交");
          } else {
            Toast.fail("提交失败");
          }
        })
        .catch(() => {
          // console.log('取消')
        });
    },
    onFailed(err) {
      if (err.errors.length > 0) {
        Notify({ type: "danger", message: err.errors[0].message });
      }
    },
    lastStep() {
      let top = document.documentElement.scrollTop || document.body.scrollTop;
      this.stepTop[this.step] = top;

      this.step = this.step - 1;
      let toTop = this.stepTop[this.step];
      if (toTop > 0) this.toTop(toTop);
    },
    getTop() {
      let top = this.stepTop[this.step + 1];
      let toTop = document.documentElement.scrollTop || document.body.scrollTop;
      this.stepTop[this.step] = toTop;
      if (top) this.toTop(top);
      else if (toTop > 0) this.toTop(0);
    },
    toTop(top) {
      document.body.scrollTop = document.documentElement.scrollTop = top;
    },
    onBirthdayConfirm(time) {
      this.formData.birthday = DATE_FORMAT.originToDate(time);
      this.showPicker = false;
    },

    // 获取手机验证码
    getPhoneCode() {
      // 验证手机号
      if (!this.phone) return Toast("手机号不能为空");
      else {
        let isPhone = this.phoneVerify.test(this.phone);
        if (!isPhone) return Toast("手机格式不正确！");
      }

      const _this = this;

      // eslint-disable-next-line no-undef
      let captcha = new TencentCaptcha(_this.appid, function (res) {
        // 返回结果
        // ret         Int       验证结果，0：验证成功。2：用户主动关闭验证码。
        // ticket      String    验证成功的票据，当且仅当 ret = 0 时 ticket 有值。
        // CaptchaAppId       String    验证码应用ID。
        // bizState    Any       自定义透传参数。
        // randstr     String    本次验证的随机串，请求后台接口时需带上。
        // console.log(res)

        // res（用户主动关闭验证码）= {ret: 2, ticket: null}
        // res（验证成功） = {ret: 0, ticket: "String", randstr: "String"}
        if (res.ret === 0) {
          let result = { randstr: res.randstr, ticket: res.ticket };
          _this.smsCode(result);
        }
        if (res.ret === 2) {
          console.log("取消校验");
        }
      });
      captcha.show();
    },

    // 发送验证码
    async smsCode(values) {
      const params = {
        appKey: this.appKey,
        phone: this.phone,
        randstr: values.randstr,
        ticket: values.ticket,
      };
      Toast.loading({ message: "加载中...", forbidClick: true, duration: 0 });

      const { resultCode, resultData, resultMsg } = await apiSmsCode({
        ...params,
      });

      if (resultCode == "200") {
        Toast.success("验证码已发送");
        let timer = setInterval(() => {
          this.timerValue = this.timerValue
            ? this.timerValue - 1
            : this.initTimerValue - 1;
          if (this.timerValue === 0) {
            clearInterval(timer);
            this.timerValue = "";
          } // 退出倒计时
        }, 1000);
      } else Toast.fail(resultMsg);
    },
  },
  watch: {
    phone(val) {
      if (val && this.code) this.disabled = false;
      else this.disabled = true;
    },
    code(val) {
      if (val && this.phone) this.disabled = false;
      else this.disabled = true;
    },
  },
};
</script>

<style lang="stylus" rel="stylesheet/stylus"></style>

