import Cookies from "js-cookie";

const getToken = () => {
  return Cookies.get('token')
}
const setToken = (token, endtime) => {
  return Cookies.set('token', token, endtime)
}
const removeToken = () => {
  return Cookies.remove('token')
}

const getSessionId = () => {
  return Cookies.get('sessionId')
}
const setSessionId = (session_id, endtime) => {
  return Cookies.set('sessionId', session_id, endtime)
}
const removeSessionId = () => {
  return Cookies.remove('sessionId')
}

const getUserInfo = () => {
  return Cookies.get('userInfo')
}
const setUserInfo = (user_info, endtime) => {
  return Cookies.set('userInfo', user_info, endtime)
}
const removeUserInfo = () => {
  return Cookies.remove('userInfo')
}

export {
  getToken,
  setToken,
  removeToken,
  getSessionId,
  setSessionId,
  removeSessionId,
  getUserInfo,
  setUserInfo,
  removeUserInfo
}