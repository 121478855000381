<template>
  <div>
    <!-- <layout-head /> -->
    <keep-alive>
      <router-view />
    </keep-alive>
  </div>
</template>

<script>
// import LayoutHead from '@/components/common/head.vue'
// import { ConfigProvider } from "vant";

export default {
  components: {
    // LayoutHead
    // ConfigProvider,
  },
};
</script>

