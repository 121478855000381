<template>
  <div class="container">
    <div class="wrapper" v-if="!showModal">
      <van-sticky>
        <div class="step-box">
          <van-steps :active="step" active-color="#38f">
            <van-step>基础信息</van-step>
            <van-step>抑郁自评量表</van-step>
            <van-step>焦虑自评量表</van-step>
            <van-step>完成</van-step>
          </van-steps>
        </div>
      </van-sticky>
      <van-form @submit="onSubmit" @failed="onFailed">
        <div v-if="step == 0">
          <div class="form-title">基础信息</div>
          <div class="form-content">
            <van-field
              v-model="formData.name"
              name="name"
              label="姓名"
              maxlength="15"
              placeholder="请填写姓名"
              :rules="[{ required: true, message: '请填写姓名' }]"
            />
            <van-field name="sex" label="性别">
              <template #input>
                <van-radio-group v-model="formData.sex" direction="horizontal">
                  <van-radio name="男">男</van-radio>
                  <van-radio name="女">女</van-radio>
                </van-radio-group>
              </template>
            </van-field>
            <!-- <van-field
              v-model="formData.age"
              name="age"
              label="年龄"
              type="digit"
              maxlength="3"
              placeholder="请填写年龄"
              :rules="[{ required: true, message: '请填写年龄' }]"
            /> -->
            <van-field
              readonly
              clickable
              name="birthday"
              :value="formData.birthday"
              label="出生日期"
              placeholder="点击选择出生年月日"
              @click="showPicker = true"
              :rules="[{ required: true, message: '请选择出生日期' }]"
            />
            <van-popup v-model="showPicker" position="bottom">
              <van-datetime-picker
                v-model="defaultDate"
                type="date"
                @confirm="onBirthdayConfirm"
                @cancel="showPicker = false"
                :min-date="minDate"
                :max-date="maxDate"
              />
            </van-popup>

            <van-field
              v-model="formData.height"
              name="height"
              label="身高"
              type="number"
              maxlength="5"
              placeholder="请填写身高"
              :rules="[{ required: true, message: '请填写身高' }]"
            >
              <span slot="right-icon">cm</span>
            </van-field>
            <van-field
              v-model="formData.weight"
              name="weight"
              label="体重"
              type="number"
              maxlength="5"
              placeholder="请填写体重"
              :rules="[{ required: true, message: '请填写体重' }]"
            >
              <span slot="right-icon">kg</span>
            </van-field>
            <van-field name="smoke" label="是否吸烟">
              <template #input>
                <van-radio-group
                  v-model="formData.smoke"
                  direction="horizontal"
                >
                  <van-radio name="否">否</van-radio>
                  <van-radio name="是">是</van-radio>
                </van-radio-group>
              </template>
            </van-field>
            <van-field
              v-if="formData.smoke === '是'"
              v-model="formData.smokeYear"
              name="smokeYear"
              label="吸烟年限"
              type="number"
              placeholder="请填写吸烟年限"
              :rules="[
                {
                  required: formData.smoke === '是' ? true : false,
                  message: '请填写吸烟年限',
                },
              ]"
            >
              <span slot="right-icon">年</span>
            </van-field>
            <van-field name="drink" label="是否饮酒">
              <template #input>
                <van-radio-group
                  v-model="formData.drink"
                  direction="horizontal"
                >
                  <van-radio name="否">否</van-radio>
                  <van-radio name="是">是</van-radio>
                  <!-- <van-radio :name="偶尔">偶尔</van-radio>
                  <van-radio :name="经常">经常</van-radio> -->
                </van-radio-group>
              </template>
            </van-field>
            <van-field name="ppm" label="起搏器">
              <template #input>
                <van-radio-group v-model="formData.ppm" direction="horizontal">
                  <van-radio name="无">无</van-radio>
                  <van-radio name="有">有</van-radio>
                </van-radio-group>
              </template>
            </van-field>
            <van-field name="pci" label="心脏支架">
              <template #input>
                <van-radio-group v-model="formData.pci" direction="horizontal">
                  <van-radio name="无">无</van-radio>
                  <van-radio name="有">有</van-radio>
                </van-radio-group>
              </template>
            </van-field>
          </div>

          <!-- <div class="form-title">既往病史</div>
          <div class="form-content">
            <van-field name="medicalHistory">
              <template #input>
                <van-checkbox-group v-model="formData.medicalHistory" direction="horizontal">
                  <van-checkbox name="高血压" shape="square">高血压</van-checkbox>
                  <van-checkbox name="糖尿病" shape="square">糖尿病</van-checkbox>
                  <van-checkbox name="冠心病" shape="square">冠心病</van-checkbox>
                  <van-checkbox name="心律不齐" shape="square">心律不齐</van-checkbox>
                  <van-checkbox name="脑卒中" shape="square">脑卒中</van-checkbox>
                  <van-checkbox name="其他" shape="square">其他</van-checkbox>
                </van-checkbox-group>
              </template>
            </van-field>
            <van-field
              v-if="formData.medicalHistory.indexOf('其他') != -1"
              v-model="formData.medicalHistoryOther"
              name="medicalHistoryOther"
              maxlength="8"
              placeholder="请填写其他既往病史"
              :rules="[{ required: formData.medicalHistory.indexOf('其他') != -1?true:false, message: '请填写其他既往病史' }]"
            />
          </div> -->

          <div class="form-title">平日不适症状</div>
          <div class="form-content">
            <van-field name="symptom">
              <template #input>
                <van-checkbox-group
                  v-model="formData.symptom"
                  direction="horizontal"
                >
                  <van-checkbox
                    v-for="(item, index) in symptomList"
                    :key="index"
                    :name="item"
                    shape="square"
                  >
                    {{ item }}
                  </van-checkbox>
                </van-checkbox-group>
              </template>
            </van-field>
            <van-field
              v-if="formData.symptom.indexOf('其他') != -1"
              v-model="formData.symptomOther"
              name="symptomOther"
              maxlength="8"
              placeholder="请填写其他平日不适症状"
              :rules="[
                {
                  required:
                    formData.symptom.indexOf('其他') != -1 ? true : false,
                  message: '请填写其他平日不适症状',
                },
              ]"
            />
          </div>
        </div>
        <div v-if="step > 0 && step < 3">
          <div v-html="desc" class="step-desc"></div>
        </div>
        <div v-if="step == 1">
          <div class="form-content field-box">
            <template>
              <div
                class="field-item"
                v-for="(item, index) in step1List"
                :key="index"
              >
                <div class="field-title">{{ index + 1 }}、{{ item.title }}</div>
                <!--  :rules="[{ required: true, message: '第 '+index+' 项未勾选，请选择！' }]" -->
                <van-field
                  :name="'anxiety' + '_' + index"
                  :rules="[
                    {
                      required: true,
                      message: '第 ' + (index + 1) + ' 项未勾选，请选择！',
                    },
                  ]"
                >
                  <template #input>
                    <van-radio-group v-model="anxiety[index]">
                      <template>
                        <van-radio
                          :name="v"
                          v-for="(v, i) in item.option"
                          :key="index + '-' + i"
                          >{{ v }}</van-radio
                        >
                      </template>
                    </van-radio-group>
                  </template>
                </van-field>
              </div>
            </template>
          </div>
        </div>
        <div v-if="step == 2">
          <div class="form-content field-box">
            <template>
              <div
                class="field-item"
                v-for="(item, index) in step2List"
                :key="index"
              >
                <div class="field-title">{{ index + 1 }}、{{ item.title }}</div>
                <van-field
                  :name="'depressed' + '_' + index"
                  :rules="[
                    {
                      required: true,
                      message: '第 ' + (index + 1) + ' 项未勾选，请选择！',
                    },
                  ]"
                >
                  <template #input>
                    <van-radio-group v-model="depressed[index]">
                      <template>
                        <van-radio
                          :name="v"
                          v-for="(v, i) in item.option"
                          :key="index + '-' + i"
                          >{{ v }}</van-radio
                        >
                      </template>
                    </van-radio-group>
                  </template>
                </van-field>
              </div>
            </template>
          </div>
        </div>
        <div v-if="step == 3">
          <div class="success-box">
            <div>
              <div class="success-img"><img :src="successImg" /></div>
              <div class="success-title">提交成功</div>
              <div class="success-desc">{{ submitTime }}</div>
            </div>
          </div>
        </div>
        <div class="form-submit-box" v-if="step < 3">
          <van-button
            v-if="step !== 0"
            round
            color="#ccc"
            @click="lastStep"
            native-type="button"
            >上一步</van-button
          >
          <van-button v-if="step !== 2" round type="info" native-type="submit"
            >下一步</van-button
          >
          <van-button
            v-if="step == 2"
            round
            block
            type="info"
            native-type="submit"
            >提交</van-button
          >
        </div>
      </van-form>
    </div>
    <van-dialog
      v-model="showModal"
      title="用户登录"
      :show-cancel-button="false"
      :show-confirm-button="false"
    >
      <van-form @submit="modalSubmit">
        <van-field
          v-model="phone"
          name="phone"
          label="手机号"
          type="tel"
          maxlength="13"
          placeholder="请输入手机号"
          :rules="[
            { required: true, message: '请输入手机号', trigger: 'onSubmit' },
            {
              pattern: phoneVerify,
              message: '请输入正确的手机号',
              trigger: 'onSubmit',
            },
          ]"
          style="margin: 20px 0 0"
        />
        <van-field
          v-model="code"
          name="code"
          label="验证码"
          maxlength="6"
          placeholder="请输入验证码"
          :rules="[
            { required: true, message: '请输入验证码', trigger: 'onSubmit' },
          ]"
        >
          <template #button>
            <van-button
              native-type="button"
              @click="getPhoneCode"
              size="small"
              type="info"
              :disabled="timerValue ? true : false"
              style="width: 78px"
            >
              {{ timerValue ? timerValue : "获取验证码" }}
            </van-button>
          </template>
        </van-field>
        <div style="margin: 16px">
          <van-button
            round
            block
            type="info"
            native-type="submit"
            :loading="loading"
            :disabled="disabled"
            loading-text="加载中..."
            >登录</van-button
          >
        </div>
      </van-form>
    </van-dialog>
  </div>
</template>

<script>
import "@/assets/stylus/index/index.styl";
import successImg from "@/assets/images/success.png";
import { Notify, Toast, Dialog } from "vant";
import { apiSubmit, apiSmsCode, apiLogin, apiUserInfo } from "@/request";
import { DATE_FORMAT } from "@/actions";
import {
  setToken,
  setSessionId,
  setUserInfo,
  getToken,
  getSessionId,
  getUserInfo,
  removeToken,
  removeSessionId,
  removeUserInfo,
} from "@/uitls/cookies";
import "@/uitls/TCaptcha";

export default {
  name: "Index2",
  data() {
    return {
      appid: "2049979663",
      appKey: "w_h5ecg",
      initTimerValue: 60,
      timerValue: "",
      showModal: true,
      step: 0,
      showPicker: false,
      loading: false,
      disabled: process.env.NODE_ENV == "development" ? false : true,
      phone: process.env.NODE_ENV == "development" ? "13800138000" : "",
      code: process.env.NODE_ENV == "development" ? "123456" : "",
      formData: {
        name: "",
        sex: "男",
        birthday: "",
        // age: "",
        height: "",
        weight: "",
        smoke: "否",
        smokeYear: "",
        drink: "否",
        ppm: "无",
        pci: "无",
        // medicalHistory: [],
        // medicalHistoryOther: "",
        symptom: [],
        symptomOther: "",
        result1: "",
        result2: "",
        score1: "",
        score2: "",
      },
      minDate: new Date(1900, 0, 1),
      maxDate: new Date(),
      defaultDate: new Date(1990, 0, 1),
      phoneVerify: /^((1[3-9]\d{9}))$/, // /^((0\d{2,3}-\d{8})|(1[3-9]\d{9}))$/,
      anxiety: [], // 抑郁
      depressed: [], // 焦虑
      stepTop: [],
      submitTime: "",
      successImg,
      symptomList: ["胸闷", "头晕", "心悸", "气短", "晕厥", "黑蒙", "其他"],
      step1List: [
        {
          title: "我感到情绪沮丧，郁闷",
          option: ["没有或偶尔", "有时", "经常", "总是如此"],
        },
        {
          title: "我感到早晨心情最好",
          option: ["没有或偶尔", "有时", "经常", "总是如此"],
        },
        {
          title: "我要哭或想哭",
          option: ["没有或偶尔", "有时", "经常", "总是如此"],
        },
        {
          title: "我夜间睡眠不好",
          option: ["没有或偶尔", "有时", "经常", "总是如此"],
        },
        {
          title: "我吃饭像平常一样多",
          option: ["没有或偶尔", "有时", "经常", "总是如此"],
        },
        {
          title: "我的性功能正常",
          option: ["没有或偶尔", "有时", "经常", "总是如此"],
        },
        {
          title: "我感到体重减轻",
          option: ["没有或偶尔", "有时", "经常", "总是如此"],
        },
        {
          title: "我为便秘烦恼",
          option: ["没有或偶尔", "有时", "经常", "总是如此"],
        },
        {
          title: "我的心跳比平时快",
          option: ["没有或偶尔", "有时", "经常", "总是如此"],
        },
        {
          title: "我无故感到疲乏",
          option: ["没有或偶尔", "有时", "经常", "总是如此"],
        },
        {
          title: "我的头脑像平常一样清楚",
          option: ["没有或偶尔", "有时", "经常", "总是如此"],
        },
        {
          title: "我做事情像平常一样不感到困难",
          option: ["没有或偶尔", "有时", "经常", "总是如此"],
        },
        {
          title: "我坐卧难安，难以保持平静",
          option: ["没有或偶尔", "有时", "经常", "总是如此"],
        },
        {
          title: "我对未来感到有希望",
          option: ["没有或偶尔", "有时", "经常", "总是如此"],
        },
        {
          title: "我比平时更容易激怒",
          option: ["没有或偶尔", "有时", "经常", "总是如此"],
        },
        {
          title: "我觉得决定什么事很容易",
          option: ["没有或偶尔", "有时", "经常", "总是如此"],
        },
        {
          title: "我感到自己是有用的和不可缺少的人",
          option: ["没有或偶尔", "有时", "经常", "总是如此"],
        },
        {
          title: "我的生活很有意思",
          option: ["没有或偶尔", "有时", "经常", "总是如此"],
        },
        {
          title: "假若我死了，别人会过得更好",
          option: ["没有或偶尔", "有时", "经常", "总是如此"],
        },
        {
          title: "我仍旧喜欢自己平时喜欢的东西",
          option: ["没有或偶尔", "有时", "经常", "总是如此"],
        },
      ],
      step2List: [
        {
          title: "我感到比往常更加神经过敏和焦虑",
          option: ["没有或偶尔", "有时", "经常", "总是如此"],
        },
        {
          title: "我无缘无故感到担心",
          option: ["没有或偶尔", "有时", "经常", "总是如此"],
        },
        {
          title: "我容易心烦意乱或感到恐慌",
          option: ["没有或偶尔", "有时", "经常", "总是如此"],
        },
        {
          title: "我感到我的身体好像被分成几块，支离破碎",
          option: ["没有或偶尔", "有时", "经常", "总是如此"],
        },
        {
          title: "我感到事事都很顺利，不会有倒霉的事情发生",
          option: ["没有或偶尔", "有时", "经常", "总是如此"],
        },
        {
          title: "我的四肢抖动和震颤",
          option: ["没有或偶尔", "有时", "经常", "总是如此"],
        },
        {
          title: "我因头痛、颈痛和背痛而烦恼",
          option: ["没有或偶尔", "有时", "经常", "总是如此"],
        },
        {
          title: "我感到无力且容易疲劳",
          option: ["没有或偶尔", "有时", "经常", "总是如此"],
        },
        {
          title: "我感到很平静，能安静坐下来",
          option: ["没有或偶尔", "有时", "经常", "总是如此"],
        },
        {
          title: "我感到我的心跳较快",
          option: ["没有或偶尔", "有时", "经常", "总是如此"],
        },
        {
          title: "我因阵阵的眩晕而不舒服",
          option: ["没有或偶尔", "有时", "经常", "总是如此"],
        },
        {
          title: "我有阵阵要昏倒的感觉",
          option: ["没有或偶尔", "有时", "经常", "总是如此"],
        },
        {
          title: "我呼吸时进气和出气都不费劲",
          option: ["没有或偶尔", "有时", "经常", "总是如此"],
        },
        {
          title: "我的手指和脚趾感到麻木和刺痛",
          option: ["没有或偶尔", "有时", "经常", "总是如此"],
        },
        {
          title: "我因胃痛和消化不良而苦恼",
          option: ["没有或偶尔", "有时", "经常", "总是如此"],
        },
        {
          title: "我必须时常排尿",
          option: ["没有或偶尔", "有时", "经常", "总是如此"],
        },
        {
          title: "我的手总是温暖和干燥",
          option: ["没有或偶尔", "有时", "经常", "总是如此"],
        },
        {
          title: "我觉得脸发烧发红",
          option: ["没有或偶尔", "有时", "经常", "总是如此"],
        },
        {
          title: "我容易入睡，晚上休息很好",
          option: ["没有或偶尔", "有时", "经常", "总是如此"],
        },
        {
          title: "我做噩梦",
          option: ["没有或偶尔", "有时", "经常", "总是如此"],
        },
      ],
      stepdesc:
        `<h3>填表注意事项：</h3>` +
        `<ul>` +
        `<li>下面有20条题目，请仔细阅读每一条，每一条文字后有四个格，分别表示：</li>` +
        `<li><strong>没有或偶尔</strong>：过去一周内，出现这类情况的日子不超过1天</li>` +
        `<li><strong>有时</strong>：过去一周内，有1-2天有过这类情况</li>` +
        `<li><strong>经常</strong>：过去一周内，3-4天有过这类情况</li>` +
        `<li><strong>总是如此</strong>：过去一周内，有5-7天有过这类情况</li>` +
        `</ul>`,
    };
  },
  mounted() {
    document.body.scrollTop = 0;
    if (getToken() && getUserInfo()) {
      this.showModal = false;
      let originData = JSON.parse(getUserInfo());
      this.initDatas(originData); // 初始化表达数据
    }
  },
  beforeDestroy() {},
  methods: {
    // 初始化表单数据
    initDatas(obj) {
      if (obj.symptom) {
        let arr1 = this.symptomList;
        let arr2 = obj.symptom.split(",");
        let intersection = arr1.filter((item) => arr2.includes(item)); // 返回交集数组
        let difference = arr2.filter((v) => !arr1.includes(v)); // 返回相差元素数组
        if (difference.length > 0) {
          intersection.push("其他");
        }
        obj.symptom = intersection;
        obj.symptomOther = difference.toString();
      }

      if (obj.result1) this.anxiety = obj.result1.split(",");

      if (obj.result2) this.depressed = obj.result2.split(",");

      if (obj.birthday)
        this.defaultDate = new Date(
          Number(obj.birthday.split("-")[0]),
          Number(obj.birthday.split("-")[1]) - 1,
          Number(obj.birthday.split("-")[2])
        );

      for (let i in this.formData) {
        if (obj[i]) this.formData[i] = obj[i];
      }
    },

    // 登录提交
    async modalSubmit(values) {
      Toast.loading({ message: "加载中...", forbidClick: true, duration: 0 });
      this.loading = true;
      this.disabled = true;
      let params = JSON.parse(JSON.stringify(values));
      params.appKey = this.appKey;
      const res = await apiLogin({
        ...params,
      });
      if (res.resultCode === "200") {
        setToken(res.resultData.access_token);
        setSessionId(res.resultData.refresh_token);
        const ret = await apiUserInfo({ userId: res.resultData.info.userId });
        if (ret.resultCode === "200") {
          setUserInfo(JSON.stringify(ret.resultData));
          this.initDatas(ret.resultData); // 初始化表单数据
          Toast.success("登录成功");
          this.showModal = false;
        } else Toast.fail(ret.resultMsg);
      } else Toast.fail(res.resultMsg);
      this.loading = false;
      this.disabled = false;
    },
    async onSubmit(values) {
      // 记录上下步骤滚动高度 并 回到到记录位置

      this.getTop();

      if (this.step == 1 || this.step == 2) {
        const arr = [];
        for (let i in values) {
          if (!values[i] && values[i] != 0) values[i] = "-";
          arr.push(values[i]);
        }
        let sum = 0;
        if (this.step == 1) {
          const sortArr = [1, 4, 5, 10, 11, 13, 15, 16, 17, 19];
          for (let i in arr) {
            if (sortArr.indexOf(Number(i)) > -1) {
              if (arr[i] == "没有或偶尔") sum = sum + 4;
              else if (arr[i] == "有时") sum = sum + 3;
              else if (arr[i] == "经常") sum = sum + 2;
              else if (arr[i] == "总是如此") sum = sum + 1;
            } else {
              if (arr[i] == "没有或偶尔") sum = sum + 1;
              else if (arr[i] == "有时") sum = sum + 2;
              else if (arr[i] == "经常") sum = sum + 3;
              else if (arr[i] == "总是如此") sum = sum + 4;
            }
          }
          this.formData.score1 = sum * 1.25;
          this.formData.result1 = arr.join(",");
        }
        if (this.step == 2) {
          const sortArr = [4, 8, 12, 16, 18];
          for (let i in arr) {
            if (sortArr.indexOf(Number(i)) > -1) {
              if (arr[i] == "没有或偶尔") sum = sum + 4;
              else if (arr[i] == "有时") sum = sum + 3;
              else if (arr[i] == "经常") sum = sum + 2;
              else if (arr[i] == "总是如此") sum = sum + 1;
            } else {
              if (arr[i] == "没有或偶尔") sum = sum + 1;
              else if (arr[i] == "有时") sum = sum + 2;
              else if (arr[i] == "经常") sum = sum + 3;
              else if (arr[i] == "总是如此") sum = sum + 4;
            }
          }
          this.formData.score2 = sum * 1.25;
          this.formData.result2 = arr.join(",");
        }
      }

      if (this.step == 2) {
        const obj = new Object();
        obj.name = this.formData.name;
        obj.sex = this.formData.sex;
        obj.birthday = this.formData.birthday;
        // obj.age = this.formData.age;
        obj.height = this.formData.height; // ? this.formData.height + "cm" : "";
        obj.weight = this.formData.weight; // ? this.formData.weight + "kg" : "";
        obj.smoke = this.formData.smoke;
        if (this.formData.smoke === "是")
          obj.smokeYear = this.formData.smokeYear;
        obj.drink = this.formData.drink;
        obj.ppm = this.formData.ppm; // 起搏器
        obj.pci = this.formData.pci; // 心脏支架
        // obj.medicalHistory =
        //   this.formData.medicalHistory.toString() +
        //   (this.formData.medicalHistoryOther
        //     ? "," + this.formData.medicalHistoryOther
        //     : "");  // 既往病史
        obj.result1 = this.formData.result1;
        obj.result2 = this.formData.result2;
        obj.score1 = this.formData.score1;
        obj.score2 = this.formData.score2;

        let symptom = JSON.parse(JSON.stringify(this.formData.symptom));
        if (symptom.indexOf("其他") != -1) {
          symptom.splice(symptom.indexOf("其他"), 1);
          symptom.push(this.formData.symptomOther);
        }
        obj.symptom = symptom.toString(); // 平日不适症状

        console.log("submit", obj);
        Dialog.confirm({
          title: "信息确认",
          message: "请确认以上填写的信息真实无误？",
        })
          .then(async () => {
            const { resultCode } = await apiSubmit(
              { ...obj },
              { headers: { Authorization: "Bearer " + getToken() } }
            );
            if (resultCode == "200") {
              this.submitTime = DATE_FORMAT.originToDateTime(new Date());
              Object.assign(this.$data.formData, this.$options.data().formData);
              removeToken();
              removeSessionId();
              removeUserInfo();
              this.step = 3;
            } else if (resultCode == "506") {
              Toast.fail("该账号已提交，请勿重复提交");
            } else {
              Toast.fail("提交失败");
            }
          })
          .catch(() => {
            // console.log('取消')
          });
      }

      // 下一步操作
      if (this.step < 2) this.step = this.step + 1;
    },
    onFailed(err) {
      // console.log(err)
      if (err.errors.length > 0) {
        // Toast.fail(err.errors[0].message);
        Notify({ type: "danger", message: err.errors[0].message });
      }
    },
    lastStep() {
      // console.log(this.stepTop)
      let top = document.documentElement.scrollTop || document.body.scrollTop;
      this.stepTop[this.step] = top;

      this.step = this.step - 1;
      let toTop = this.stepTop[this.step];
      // if(this.step == 1) console.log(this.$refs.step1)
      if (toTop > 0) this.toTop(toTop);
    },
    getTop() {
      let top = this.stepTop[this.step + 1];
      let toTop = document.documentElement.scrollTop || document.body.scrollTop;
      this.stepTop[this.step] = toTop;
      if (top) this.toTop(top);
      else if (toTop > 0) this.toTop(0);
      // console.log(this.stepTop)
    },
    toTop(top) {
      // console.log(8)
      document.body.scrollTop = document.documentElement.scrollTop = top;
      // 实现滚动效果
      // const timeTop = setInterval(() => {
      //   document.body.scrollTop = document.documentElement.scrollTop = top -= 60; // 控制滚动速度
      //   if (top <= 0) {
      //     clearInterval(timeTop);
      //   }
      // }, 10);
    },
    // nextStep(){
    //   console.log(this.step)
    //   this.step = this.step - 1
    //   console.log(this.step)
    // },
    onBirthdayConfirm(time) {
      this.formData.birthday = DATE_FORMAT.originToDate(time);
      this.showPicker = false;
    },

    // 获取手机验证码
    getPhoneCode() {
      // 验证手机号
      if (!this.phone) return Toast("请输入手机号");
      else {
        let isPhone = this.phoneVerify.test(this.phone);
        if (!isPhone) return Toast("请输入正确的手机号！");
      }

      const _this = this;

      // eslint-disable-next-line no-undef
      let captcha = new TencentCaptcha(_this.appid, function (res) {
        // 返回结果
        // ret         Int       验证结果，0：验证成功。2：用户主动关闭验证码。
        // ticket      String    验证成功的票据，当且仅当 ret = 0 时 ticket 有值。
        // CaptchaAppId       String    验证码应用ID。
        // bizState    Any       自定义透传参数。
        // randstr     String    本次验证的随机串，请求后台接口时需带上。
        // console.log(res)

        // res（用户主动关闭验证码）= {ret: 2, ticket: null}
        // res（验证成功） = {ret: 0, ticket: "String", randstr: "String"}
        if (res.ret === 0) {
          let result = { randstr: res.randstr, ticket: res.ticket };
          _this.smsCode(result);
        }
        if (res.ret === 2) {
          console.log("取消校验");
        }
      });
      captcha.show();
    },

    // 发送验证码
    async smsCode(values) {
      const params = {
        appKey: this.appKey,
        phone: this.phone,
        randstr: values.randstr,
        ticket: values.ticket,
      };
      Toast.loading({ message: "加载中...", forbidClick: true, duration: 0 });

      const { resultCode, resultData, resultMsg } = await apiSmsCode({
        ...params,
      });

      if (resultCode == "200") {
        Toast.success("验证码已发送");
        let timer = setInterval(() => {
          this.timerValue = this.timerValue
            ? this.timerValue - 1
            : this.initTimerValue - 1;
          if (this.timerValue === 0) {
            clearInterval(timer);
            this.timerValue = "";
          } // 退出倒计时
        }, 1000);
      } else Toast.fail(resultMsg);
    },
  },
  computed: {},
  watch: {
    step(val) {
      if (val == 1 || val == 2) {
        this.desc = this.stepdesc;
      }
    },
    phone(val) {
      if (val && this.code) this.disabled = false;
      else this.disabled = true;
    },
    code(val) {
      if (val && this.phone) this.disabled = false;
      else this.disabled = true;
    },
  },
};
</script>

<style lang="stylus" rel="stylesheet/stylus"></style>

